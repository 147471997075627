import axios from "axios";

export const createMailchimpAbandonedBasket = (data) => {

    const config = {
        baseURL: `${process.env.STRIPE_SVC_BACKEND_BASE_URL}`,
        auth: {
            username: `${process.env.STRIPE_SVC_BACKEND_USERNAME}`,
            password: `${process.env.STRIPE_SVC_BACKEND_PASSWORD}`
        },
    };

    return axios.post(`/api/v1/mailchimp/lists/${process.env.MAILCHIMP_CHECKOUT_MARKETING_LIST_ID}/members`, data, config);
};
