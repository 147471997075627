import React, { useState, useEffect } from "react"
import Checkmark from "../images/icon-cart-icon-check.inline.svg"
import { createStructuredSelector } from "reselect"
import { Row, Form, Col, Button } from "react-bootstrap"
import { connect } from "react-redux"
import { basketActions, basketSelectors } from "../state/basket"
import { navigate } from "gatsby"
import { createMailchimpAbandonedBasket } from "../api/mailchimp"
import { dataLayerUpdate } from "../api/dataLayer/gaDataLayer"

import * as style from "./homeDelivery.module.scss"

const HomeDelivery = ({
  basketItems,
  basketTotal,
  basketTotalWithDiscount,
  isSelected,
  saveFormDetails,
  additionalServices,
  financeCheckout,
  calculateShippingFee
}
) => {
  useEffect(() => {
      !financeCheckout &&
      basketItems &&
      dataLayerUpdate('begin_checkout',
          {'basketItems':basketItems, 'basketTotal':basketTotal, 'basketTotalWithDiscount':basketTotalWithDiscount}
      )
  }, [])
  const [acceptedMarketing, setAcceptedMarketing] = useState(false)
  const [formValidated, setFormValidated] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    postcode: "",
    city: "",
    phoneNumber: "",
  })
  const {
    firstName,
    lastName,
    email,
    addressLine1,
    addressLine2,
    postcode,
    city,
    phoneNumber,
  } = formData

  const buttonId = financeCheckout === true ? "financeHomeDeliveryContinue" : "checkoutHomeDeliveryContinue"

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  const handleSubmit = async ev => {
    ev.preventDefault()
    setProcessing(true)
    // form validation
    const form = ev.currentTarget

    if (form.checkValidity() === false) {
      ev.stopPropagation()
      setFormValidated(true)
      setProcessing(false)
      return
    } else {
      setFormValidated(false)
      const payload = {
        ...formData,
        isActive: isSelected,
        deliveryMethod: "homeDelivery",
        additionalServices: additionalServices,
        shippingMethod: calculateShippingFee > 0 ? "FLAT_RATE" : "FREE"
      }
      saveFormDetails(payload)
      if(acceptedMarketing) {
        const data = {
          "emailAddress": email,
        }
        await createMailchimpAbandonedBasket(data)
          .then(response => {})
          .catch(error => console.log(error.response));
      }
       if(financeCheckout === true)  {
        navigate('/finance-checkout-summary')
      }
      else {
        navigate("/checkout")
      }
    }
  }

  return isSelected ? (
    <>
      <Form
        id="contactForm"
        action={`${process.env.DELIVERY_WORDPRESS_FORM_URL}`}
        noValidate
        validated={formValidated}
        onSubmit={handleSubmit}
        method="post">
        {/*  SHIPPING ADDRES  */}
        <p className={`my-4`}>
          {`${process.env.HOME_DELIVERY_MARKETING_CONTENT}`}
        </p>

        <div className=" mt-4 media d-lg-block">
          <div className="media-body d-flex my-3">
            <h3 className="text-uppercase mr-2 pt-2">shipping address</h3>
            <span className="mt-1">
              <Checkmark />
            </span>
          </div>
        </div>
        <Row>
          <Col xs={10} lg={6}>
            <Form.Group className={style.inputSideBySide} controlId="firstName">
              <Form.Label>First Name*</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                placeholder="Emily"
                className="rounded-0 p-3"
                onChange={e => onChange(e)}
                value={firstName}
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name*</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                placeholder="E.g Robins"
                className="rounded-0 p-3"
                onChange={e => onChange(e)}
                value={lastName}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Group controlId="email">
              <Form.Label>Email*</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="E.g arianne.whittaker@gmail.com"
                className={` rounded-0 p-3`}
                onChange={e => onChange(e)}
                value={email}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="addressLine1">
              <Form.Label>Address Line 1*</Form.Label>
              <Form.Control
                type="text"
                name="addressLine1"
                placeholder="E.g 68 Charlotte St"
                className="rounded-0 p-3"
                onChange={e => onChange(e)}
                value={addressLine1}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="addressLine2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                name="addressLine2"
                placeholder="Bloomsbury"
                className="rounded-0 p-3"
                onChange={e => onChange(e)}
                value={addressLine2}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={10} lg={6}>
            <Form.Group className={style.inputSideBySide} controlId="postCode">
              <Form.Label>Postcode*</Form.Label>
              <Form.Control
                type="text"
                name="postcode"
                placeholder="E.g E1 6AN"
                className="rounded-0 p-3"
                onChange={e => onChange(e)}
                value={postcode}
                required
              />
            </Form.Group>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Group controlId="city">
              <Form.Label>City*</Form.Label>
              <Form.Control
                type="text"
                name="city"
                placeholder="E.g London"
                className="rounded-0 p-3"
                onChange={e => onChange(e)}
                value={city}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Group controlId="city">
              <Form.Label>
                Phone Number <span className="gray-600">*</span>
              </Form.Label>
              <Form.Control
                type="tel"
                name="phoneNumber"
                placeholder="E.g 020 4134 5678"
                className="rounded-0 p-3"
                onChange={e => onChange(e)}
                required
                value={phoneNumber}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="d-block px-3 my-4">
          <Form.Check
              type="checkbox"
              id="confirmation"
              label={
                <div>
                  <span dangerouslySetInnerHTML={{__html: process.env.MARKETING_PERMISSIONS}} />
                </div>
              }
              onClick={() => setAcceptedMarketing(!acceptedMarketing)}
          />
        </Row>

        <Button
          className={`px-4 mt-3 mr-xl-0 btn-block ${style.payBtn}`}
          variant="primary"
          id={buttonId}
          type="submit">
          {processing ? (
            "Processing..."
          ) : (
            <span className="ml-2 align-middle">{financeCheckout === true ? "Next Step" : "Continue To Payment"}</span>
          )}
        </Button>
      </Form>
    </>
  ) : null
}

const mapStateToProps = createStructuredSelector({
  basketTotal: basketSelectors.getBasketTotal,
  basketTotalWithDiscount: basketSelectors.getBasketTotalWithDiscount,
  additionalServices: basketSelectors.getAdditionalServices,
  basketItems: basketSelectors.getItems,
  calculateShippingFee: basketSelectors.calculateShippingFee,
})

const mapDispatchToProps = {
  saveFormDetails: basketActions.saveFormDetails,
  addAdditionalServices: basketActions.addAdditionalServices,
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeDelivery)
