// extracted by mini-css-extract-plugin
export var addressCheckbox = "homeDelivery-module--addressCheckbox--39732";
export var bgGrey100 = "homeDelivery-module--bg-grey-100--f0dbf";
export var bgGrey150 = "homeDelivery-module--bg-grey-150--b4019";
export var bgGrey200 = "homeDelivery-module--bg-grey-200--6dd56";
export var bgGrey300 = "homeDelivery-module--bg-grey-300--389c2";
export var bgGrey400 = "homeDelivery-module--bg-grey-400--8796e";
export var bgGrey500 = "homeDelivery-module--bg-grey-500--a1caa";
export var bgGrey600 = "homeDelivery-module--bg-grey-600--3cf35";
export var bgGrey700 = "homeDelivery-module--bg-grey-700--42764";
export var bgGrey800 = "homeDelivery-module--bg-grey-800--f4c78";
export var bgGrey900 = "homeDelivery-module--bg-grey-900--935d3";
export var bgOverlay = "homeDelivery-module--bgOverlay--286fd";
export var bikeImg = "homeDelivery-module--bikeImg--0f1bc";
export var blackBorder = "homeDelivery-module--blackBorder--7501c";
export var blackColor = "homeDelivery-module--blackColor--529a8";
export var builtDiv = "homeDelivery-module--builtDiv--3ec87";
export var countProducts = "homeDelivery-module--countProducts--25eda";
export var deliveryOptionContainer = "homeDelivery-module--deliveryOptionContainer--3e3e6";
export var deliveryOptions = "homeDelivery-module--deliveryOptions--f814a";
export var deliveryOptionsMob = "homeDelivery-module--deliveryOptionsMob--6bb8b";
export var grayLine = "homeDelivery-module--grayLine--c152a";
export var grayText = "homeDelivery-module--grayText--37f83";
export var greyBorder = "homeDelivery-module--greyBorder--7d476";
export var greyColor = "homeDelivery-module--greyColor--c7ecb";
export var helpText = "homeDelivery-module--helpText--03dfb";
export var inputContainer = "homeDelivery-module--inputContainer--300d8";
export var inputSideBySide = "homeDelivery-module--inputSideBySide--04cb2";
export var marketingBg = "homeDelivery-module--marketingBg--858ff";
export var mobilePriceSticker = "homeDelivery-module--mobilePriceSticker--70d0c";
export var needHelp = "homeDelivery-module--needHelp--dc7df";
export var orderSummary = "homeDelivery-module--orderSummary--3a3e7";
export var payBtn = "homeDelivery-module--payBtn--06537";
export var priceSticker = "homeDelivery-module--priceSticker--5e30f";
export var redBox = "homeDelivery-module--redBox--c7e79";
export var rideDiv = "homeDelivery-module--rideDiv--65662";
export var section = "homeDelivery-module--section--1d533";
export var servicesText = "homeDelivery-module--servicesText--f646a";
export var textGrey100 = "homeDelivery-module--text-grey-100--b1714";
export var textGrey150 = "homeDelivery-module--text-grey-150--4d5e0";
export var textGrey200 = "homeDelivery-module--text-grey-200--38237";
export var textGrey300 = "homeDelivery-module--text-grey-300--37d96";
export var textGrey400 = "homeDelivery-module--text-grey-400--d0855";
export var textGrey500 = "homeDelivery-module--text-grey-500--4e76c";
export var textGrey600 = "homeDelivery-module--text-grey-600--51971";
export var textGrey700 = "homeDelivery-module--text-grey-700--6cfca";
export var textGrey800 = "homeDelivery-module--text-grey-800--c567d";
export var textGrey900 = "homeDelivery-module--text-grey-900--a15b1";
export var timeSection = "homeDelivery-module--timeSection--e0b2e";